<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.hauptkostenstelle.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.hauptkostenstelle.list.title"></app-i18n>
      </h1>

      <app-hauptkostenstelle-list-toolbar></app-hauptkostenstelle-list-toolbar>
      <app-hauptkostenstelle-list-filter></app-hauptkostenstelle-list-filter>
      <app-hauptkostenstelle-list-table></app-hauptkostenstelle-list-table>
    </div>
  </div>
</template>

<script>
import HauptkostenstelleListFilter from '@/modules/hauptkostenstelle/components/hauptkostenstelle-list-filter.vue';
import HauptkostenstelleListTable from '@/modules/hauptkostenstelle/components/hauptkostenstelle-list-table.vue';
import HauptkostenstelleListToolbar from '@/modules/hauptkostenstelle/components/hauptkostenstelle-list-toolbar.vue';

export default {
  name: 'app-hauptkostenstelle-list-page',

  components: {
    [HauptkostenstelleListFilter.name]: HauptkostenstelleListFilter,
    [HauptkostenstelleListTable.name]: HauptkostenstelleListTable,
    [HauptkostenstelleListToolbar.name]: HauptkostenstelleListToolbar,
  },
};
</script>

<style></style>
